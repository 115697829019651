import React from 'react';
import { Link, StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Footer from "../components/Footer"
import p06OcaAudio from "../audio/p06OcaAudio.m4a"
import p06VO from "../audio/p06VO.m4a"
import p06OcaLongAudio from "../audio/p06OcaLongAudio.m4a"

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}
const images = importAll(require.context('../images/', false, /.jpg$|.png/));

class Page06 extends React.Component {
  constructor(props){
    super(props)
    this.state = { active: false,
                   cencerro: images["p06_cencerro.png"],
                   boca: false
                 }
  }
  playAudio = (x) => {
    const audioEl = document.getElementsByClassName("audio-element")[x]
    if(x===0){
      if(this.state.active){
        audioEl.play()
      }
      else{ audioEl.pause();audioEl.currentTime = 0; }
    }
    else{ audioEl.play() }
}
handleCencerroClick = () => {
  this.setState({ active: !this.state.active }, () => {
      if(this.state.active){
        setTimeout( () => {this.setState({cencerro: images["resetBotonTrans.png"]})}, 1000 );
        setTimeout( () => {this.setState({ boca: true })}, 1300 );
        this.playAudio(3);
      }
      else { this.setState({cencerro: images["p06_cencerro.png"]}) }
    })
}
openMouth = () => {
  this.setState({ boca: true });
  this.playAudio(1);
}
  render() {
    return (
      <div className="main-container">
      <Link to="/Page05">
        <div className="page-turner">
          <img style={{transform: "scaleX(-1)", opacity: 0.75}} className="hw" src={images["arrowPages.png"]} alt="" />
        </div>
      </Link>
      <Link to="/Page07">
        <div className="page-turner right-page-pos">
        <img style={{opacity: 0.75}} className="hw" src={images["arrowPages.png"]} alt="" />
        </div>
      </Link>
        <div className="centered-container">
          <div className="backContainer004">
          <StaticQuery
            query={graphql`
              query {
                imageOne: file(relativePath: { eq: "p06_back.jpg" }) {
                  childImageSharp {
                    fluid(maxWidth: 1000) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            `}
      render={data => (
        <Img fluid={data.imageOne.childImageSharp.fluid} />
      )}
    />
          </div>
          <div className="p06Cuentamelo"  onClick={() => this.playAudio(2)}>
            <img className="hw" src={images["botonCuentamelo.png"]} alt="" />
          </div>
          <div className="_idContainer070">
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p06_ganso.png"]} alt="" />
          </div>
          <div className={"_idContainer071" + (this.state.boca ? " activeFast" : "")}
            onAnimationEnd={ () => this.setState({ boca:false }) } >
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p06_bocaBott.png"]} alt="" />
          </div>
          <div className="_idContainer072">
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p06_gansoTop.png"]} alt="" />
          </div>
          <div className={"_idContainer073" + (this.state.active ? " active" : "")} >
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p06_ocaJuego.png"]} alt="" />
          </div>
          <div className={"_idCencerro" + (this.state.active ? " active" : "") } onClick={this.handleCencerroClick}>
            <img className="cencerroReset _idGenObjectAttribute-1 _idGenObjectAttribute-2" src={this.state.cencerro} alt="" />
          </div>
          <div className="_idContainer075 Basic-Text-Frame">
            <span>La oca inventaba un juego.</span>
          </div>
          <div className="_idContainer076" onClick={ this.openMouth } >
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["4256.png"]} alt="" />
          </div>
          <audio className="audio-element">
            <source src={p06OcaAudio}></source>
          </audio>
          <audio className="audio-element">
            <source src={p06OcaAudio}></source>
          </audio>
          <audio className="audio-element">
            <source src={p06VO}></source>
          </audio>
          <audio className="audio-element">
            <source src={p06OcaLongAudio}></source>
          </audio>
        </div>
      <Footer />
      </div>
      )
    }
  }

export default Page06;
